import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_424d2baa from 'nuxt_plugin_sentryserver_424d2baa' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_791ff8a3 from 'nuxt_plugin_sentryclient_791ff8a3' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_bootstrapvue_14008896 from 'nuxt_plugin_bootstrapvue_14008896' // Source: ./bootstrap-vue.js (mode: 'all')
import nuxt_plugin_image_5b76fe62 from 'nuxt_plugin_image_5b76fe62' // Source: ./image.js (mode: 'all')
import nuxt_plugin_axios_70700167 from 'nuxt_plugin_axios_70700167' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_templatesplugin5538f808_0c226c38 from 'nuxt_plugin_templatesplugin5538f808_0c226c38' // Source: ./templates.plugin.5538f808.js (mode: 'all')
import nuxt_plugin_localforage_6d383a88 from 'nuxt_plugin_localforage_6d383a88' // Source: ./localforage.js (mode: 'client')
import nuxt_plugin_moment_a78885de from 'nuxt_plugin_moment_a78885de' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_resizer_04986eef from 'nuxt_plugin_resizer_04986eef' // Source: ../plugins/resizer.js (mode: 'all')
import nuxt_plugin_global_2cabbc8e from 'nuxt_plugin_global_2cabbc8e' // Source: ../mixins/global (mode: 'all')
import nuxt_plugin_globalcomponents_91a8526c from 'nuxt_plugin_globalcomponents_91a8526c' // Source: ../plugins/global-components (mode: 'all')
import nuxt_plugin_init_6a81d586 from 'nuxt_plugin_init_6a81d586' // Source: ../plugins/init (mode: 'all')
import nuxt_plugin_vueapiquery_964c47d0 from 'nuxt_plugin_vueapiquery_964c47d0' // Source: ../plugins/vue-api-query (mode: 'all')
import nuxt_plugin_auth_6a7e4e1e from 'nuxt_plugin_auth_6a7e4e1e' // Source: ../plugins/auth (mode: 'client')
import nuxt_plugin_mapbox_50fc9436 from 'nuxt_plugin_mapbox_50fc9436' // Source: ../plugins/mapbox (mode: 'client')
import nuxt_plugin_tour_6a86dace from 'nuxt_plugin_tour_6a86dace' // Source: ../plugins/tour (mode: 'client')
import nuxt_plugin_codemirror_346d5269 from 'nuxt_plugin_codemirror_346d5269' // Source: ../plugins/code-mirror (mode: 'client')
import nuxt_plugin_penpal_46503050 from 'nuxt_plugin_penpal_46503050' // Source: ../plugins/penpal (mode: 'client')
import nuxt_plugin_echo_6a7fda9b from 'nuxt_plugin_echo_6a7fda9b' // Source: ../plugins/echo (mode: 'client')
import nuxt_plugin_vuesortabletree_541b48d4 from 'nuxt_plugin_vuesortabletree_541b48d4' // Source: ../plugins/vue-sortable-tree (mode: 'client')
import nuxt_plugin_sortable_562ad16e from 'nuxt_plugin_sortable_562ad16e' // Source: ../plugins/sortable (mode: 'client')
import nuxt_plugin_colorpicker_d0794ea4 from 'nuxt_plugin_colorpicker_d0794ea4' // Source: ../plugins/color-picker (mode: 'client')
import nuxt_plugin_iconpicker_494b2b2c from 'nuxt_plugin_iconpicker_494b2b2c' // Source: ../plugins/icon-picker (mode: 'client')
import nuxt_plugin_flatpickr_2d9eb1e2 from 'nuxt_plugin_flatpickr_2d9eb1e2' // Source: ../plugins/flatpickr (mode: 'client')
import nuxt_plugin_vselect2_7fd8e1a9 from 'nuxt_plugin_vselect2_7fd8e1a9' // Source: ../plugins/v-select2 (mode: 'client')
import nuxt_plugin_vueselect_0c6a19ce from 'nuxt_plugin_vueselect_0c6a19ce' // Source: ../plugins/vue-select (mode: 'client')
import nuxt_plugin_vuelidate_4345260a from 'nuxt_plugin_vuelidate_4345260a' // Source: ../plugins/vuelidate (mode: 'client')
import nuxt_plugin_vuetippy_73799e27 from 'nuxt_plugin_vuetippy_73799e27' // Source: ../plugins/vue-tippy (mode: 'client')
import nuxt_plugin_clickoutside_2bc8eaac from 'nuxt_plugin_clickoutside_2bc8eaac' // Source: ../plugins/click-outside (mode: 'client')
import nuxt_plugin_pannellum_26788676 from 'nuxt_plugin_pannellum_26788676' // Source: ../plugins/pannellum (mode: 'client')
import nuxt_plugin_vimeoplayer_c28cd600 from 'nuxt_plugin_vimeoplayer_c28cd600' // Source: ../plugins/vimeo-player (mode: 'client')
import nuxt_plugin_lazyyoutube_da54ef80 from 'nuxt_plugin_lazyyoutube_da54ef80' // Source: ../plugins/lazy-youtube (mode: 'client')
import nuxt_plugin_querybuilder_baa35040 from 'nuxt_plugin_querybuilder_baa35040' // Source: ../plugins/query-builder (mode: 'client')
import nuxt_plugin_vdrag_380959be from 'nuxt_plugin_vdrag_380959be' // Source: ../plugins/v-drag (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":function titleTemplate(titleChunk) {
  const title = 'CA Editor';
  return titleChunk && titleChunk !== title ?
  `${titleChunk} | ${title}` :
  title;
},"meta":[{"once":true,"name":"charset","hid":"charset","content":"utf-8"},{"once":true,"hid":"content-type","http-equiv":"Content-Type","content":"text\u002Fhtml; charset=utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"CA-EDITOR - The Community Analytics Editor"},{"property":"og:title","title":"CA Editor","template":function titleTemplate(titleChunk) {
  const title = 'CA Editor';
  return titleChunk && titleChunk !== title ?
  `${titleChunk} | ${title}` :
  title;
},"hid":"og:title"},{"name":"robots","hid":"robots","content":"noindex"}],"link":[{"once":true,"rel":"icon","hid":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"hid":"font:Montserrat","rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Montserrat:wght@400;600;700&display=swap"},{"hid":"font:Barlow","rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Barlow&display=swap"},{"hid":"css:mapbox-gl-draw","rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Fapi.mapbox.com\u002Fmapbox-gl-js\u002Fplugins\u002Fmapbox-gl-draw\u002Fv1.0.9\u002Fmapbox-gl-draw.css"},{"hid":"css:pannellum","rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002Fpannellum@2.5.6\u002Fbuild\u002Fpannellum.css"}],"script":[{"hid":"polyfills","src":"https:\u002F\u002Fcdnjs.cloudflare.com\u002Fpolyfill\u002Fv3\u002Fpolyfill.min.js?features=fetch%2CObject.entries%2CElement.prototype.classList%2CIntersectionObserver%2CArray.from%2CPromise%2CPromise.prototype.finally%2CEvent%2CEvent.focusin%2CEvent.hashchange%2CEventSource%2CCustomEvent","body":true},{"hid":"fontawesomejs","src":"https:\u002F\u002Fkit.fontawesome.com\u002F9a65a3b837.js","crossorigin":"anonymous"},{"hid":"mapboxjs","src":"https:\u002F\u002Fapi.mapbox.com\u002Fmapbox-gl-js\u002Fplugins\u002Fmapbox-gl-draw\u002Fv1.0.9\u002Fmapbox-gl-draw.js"},{"hid":"pannellumjs","src":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002Fpannellum@2.5.6\u002Fbuild\u002Fpannellum.js"},{"skip":true,"hid":"googlemapsjs","src":"https:\u002F\u002Fmaps.googleapis.com\u002Fmaps\u002Fapi\u002Fjs?key=undefined&libraries=places"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_424d2baa === 'function') {
    await nuxt_plugin_sentryserver_424d2baa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_791ff8a3 === 'function') {
    await nuxt_plugin_sentryclient_791ff8a3(app.context, inject)
  }

  if (typeof nuxt_plugin_bootstrapvue_14008896 === 'function') {
    await nuxt_plugin_bootstrapvue_14008896(app.context, inject)
  }

  if (typeof nuxt_plugin_image_5b76fe62 === 'function') {
    await nuxt_plugin_image_5b76fe62(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_70700167 === 'function') {
    await nuxt_plugin_axios_70700167(app.context, inject)
  }

  if (typeof nuxt_plugin_templatesplugin5538f808_0c226c38 === 'function') {
    await nuxt_plugin_templatesplugin5538f808_0c226c38(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_localforage_6d383a88 === 'function') {
    await nuxt_plugin_localforage_6d383a88(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_a78885de === 'function') {
    await nuxt_plugin_moment_a78885de(app.context, inject)
  }

  if (typeof nuxt_plugin_resizer_04986eef === 'function') {
    await nuxt_plugin_resizer_04986eef(app.context, inject)
  }

  if (typeof nuxt_plugin_global_2cabbc8e === 'function') {
    await nuxt_plugin_global_2cabbc8e(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_91a8526c === 'function') {
    await nuxt_plugin_globalcomponents_91a8526c(app.context, inject)
  }

  if (typeof nuxt_plugin_init_6a81d586 === 'function') {
    await nuxt_plugin_init_6a81d586(app.context, inject)
  }

  if (typeof nuxt_plugin_vueapiquery_964c47d0 === 'function') {
    await nuxt_plugin_vueapiquery_964c47d0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_auth_6a7e4e1e === 'function') {
    await nuxt_plugin_auth_6a7e4e1e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_mapbox_50fc9436 === 'function') {
    await nuxt_plugin_mapbox_50fc9436(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_tour_6a86dace === 'function') {
    await nuxt_plugin_tour_6a86dace(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_codemirror_346d5269 === 'function') {
    await nuxt_plugin_codemirror_346d5269(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_penpal_46503050 === 'function') {
    await nuxt_plugin_penpal_46503050(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_echo_6a7fda9b === 'function') {
    await nuxt_plugin_echo_6a7fda9b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuesortabletree_541b48d4 === 'function') {
    await nuxt_plugin_vuesortabletree_541b48d4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sortable_562ad16e === 'function') {
    await nuxt_plugin_sortable_562ad16e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_colorpicker_d0794ea4 === 'function') {
    await nuxt_plugin_colorpicker_d0794ea4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_iconpicker_494b2b2c === 'function') {
    await nuxt_plugin_iconpicker_494b2b2c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_flatpickr_2d9eb1e2 === 'function') {
    await nuxt_plugin_flatpickr_2d9eb1e2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vselect2_7fd8e1a9 === 'function') {
    await nuxt_plugin_vselect2_7fd8e1a9(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueselect_0c6a19ce === 'function') {
    await nuxt_plugin_vueselect_0c6a19ce(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuelidate_4345260a === 'function') {
    await nuxt_plugin_vuelidate_4345260a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetippy_73799e27 === 'function') {
    await nuxt_plugin_vuetippy_73799e27(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clickoutside_2bc8eaac === 'function') {
    await nuxt_plugin_clickoutside_2bc8eaac(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pannellum_26788676 === 'function') {
    await nuxt_plugin_pannellum_26788676(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vimeoplayer_c28cd600 === 'function') {
    await nuxt_plugin_vimeoplayer_c28cd600(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_lazyyoutube_da54ef80 === 'function') {
    await nuxt_plugin_lazyyoutube_da54ef80(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_querybuilder_baa35040 === 'function') {
    await nuxt_plugin_querybuilder_baa35040(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vdrag_380959be === 'function') {
    await nuxt_plugin_vdrag_380959be(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
